.custom-checkbox, .custom-radio {
  padding-left: 40px;
  .custom-control-label {
    &:before, &:after {
      width: 30px;
      height: 30px;
      left: -40px;
      top: -4px;
    }
  }
}

.alert {
  &.alert-xs {
    padding: 6px 12px;
  }
  &.notification {
    margin-top: 10px;
  }
}

.newlines {
  white-space: pre-line;
}

.react-select {
  flex: 1;
  text-align: left;
  > * {
    border-radius: 0 !important;
  }
}

.Circle {
  width: 150px;
  height: 150px;
  background: #000000;
  border: solid 5px #FFFFFF;
  box-shadow: 0 0 0 5px $theme-blue;
  border-radius: 50%;
  overflow: hidden;
  .Wave {
    position: relative;
    width: 100%;
    height: 100%;
    background: $theme-blue;
    box-shadow: inset 0 0 50px rgba(#000000, 0.5);
    &:before, &:after {
      content: '';
      position: absolute;
      width: 200%;
      height: 200%;
      top: 0;
      left: 50%;
      transform: translate(-50%, -75%);
      background: #000000;
    }
    &:before {
      border-radius: 45%;
      background-color: #FFFFFF;
      animation: WaveAnimation 5s linear infinite;
    }
    &:after {
      border-radius: 40%;
      background-color: rgba(#FFFFFF, 0.5);
      animation: WaveAnimation 10s linear infinite;
    }
  }
}

.PhotoWrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  margin: 0;
  background-color: $info;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  input {
    position: absolute;
    left: -99999px;
  }
  > div, > label {
    display: block;
    flex: 1;
    cursor: pointer;
    margin-bottom: 0;
    padding: $grid-gutter-width 0;
    text-align: center;
    color: #FFFFFF;
    i.fa {
      text-shadow: 0 0 5px rgba(#000000, 0.5);
    }
    span {
      font-size: 12px;
      display: inline-block;
      padding: 6px 12px;
      background: rgba(255,255,255,0.8);
      text-shadow: 0 0 0 rgba(0,0,0,0.75);
      color: $theme-blue;
      border-radius: 5px;
    }
  }
}

@keyframes WaveAnimation {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}