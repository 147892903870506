@mixin portrait() {
  @media screen and (orientation:portrait) { @content; }
}

@mixin media-portrait-up($name) {
  @include portrait {
    @include media-breakpoint-up($name) {
      @content;
    }
  }
}

@mixin media-portrait-down($name) {
  @include portrait {
    @include media-breakpoint-down($name) {
      @content;
    }
  }
}

// Landscape
@mixin landscape() {
  @media screen and (orientation:landscape) { @content; }
}

@mixin media-landscape-up($name) {
  @include landscape {
    @include media-breakpoint-up($name) {
      @content;
    }
  }
}

@mixin media-landscape-down($name) {
  @include landscape {
    @include media-breakpoint-down($name) {
      @content;
    }
  }
}