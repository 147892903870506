$theme-blue: #3d67af;
$theme-dark-blue: #304d7f;
$theme-light-blue: #cad0d9;
$theme-lighter-blue: #f0f5fc;
$theme-grey: #8c8d8e;
$theme-other-blue: #e5ebf3;
$theme-red: #801A1A;

$border-radius: 0;

$primary: $theme-dark-blue;

$link-color: $theme-blue;

$font-family-base: 'Montserrat', sans-serif;

$fa-font-path: '~font-awesome/fonts';

$container-max-widths: (
  sm: 1140px,
  md: 1141px,
  lg: 1142px,
  xl: 1143px
) !default;